import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
  if (action.type === `INCREMENT`) {
    return Object.assign({}, state, {
      count: state.count + 1
    });
  }
  if (action.type === `TOGGLE_MENU`) {
    return Object.assign({}, state, {
      menu: !state.menu
    });
  }
  if (action.type === `CLOSE_MENU`) {
    return Object.assign({}, state, {
      menu: false
    });
  }
  return state;
};

const initialState = {
  count: 0,
  menu: false
};

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
