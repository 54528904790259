// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-entries-capability-js": () => import("/opt/build/repo/src/templates/entries/capability.js" /* webpackChunkName: "component---src-templates-entries-capability-js" */),
  "component---src-templates-entries-approach-js": () => import("/opt/build/repo/src/templates/entries/approach.js" /* webpackChunkName: "component---src-templates-entries-approach-js" */),
  "component---src-templates-pages-about-js": () => import("/opt/build/repo/src/templates/pages/about.js" /* webpackChunkName: "component---src-templates-pages-about-js" */),
  "component---src-templates-pages-landing-js": () => import("/opt/build/repo/src/templates/pages/landing.js" /* webpackChunkName: "component---src-templates-pages-landing-js" */),
  "component---src-templates-pages-contact-js": () => import("/opt/build/repo/src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-our-work-js": () => import("/opt/build/repo/src/templates/pages/our-work.js" /* webpackChunkName: "component---src-templates-pages-our-work-js" */),
  "component---src-templates-pages-approach-js": () => import("/opt/build/repo/src/templates/pages/approach.js" /* webpackChunkName: "component---src-templates-pages-approach-js" */),
  "component---src-templates-pages-what-we-do-js": () => import("/opt/build/repo/src/templates/pages/what-we-do.js" /* webpackChunkName: "component---src-templates-pages-what-we-do-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("/opt/build/repo/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-test-js": () => import("/opt/build/repo/src/pages/index-test.js" /* webpackChunkName: "component---src-pages-index-test-js" */),
  "component---src-pages-landing-js": () => import("/opt/build/repo/src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-static-about-us-js": () => import("/opt/build/repo/src/pages/static-about-us.js" /* webpackChunkName: "component---src-pages-static-about-us-js" */),
  "component---src-pages-static-contact-js": () => import("/opt/build/repo/src/pages/static-contact.js" /* webpackChunkName: "component---src-pages-static-contact-js" */),
  "component---src-pages-static-what-we-do-js": () => import("/opt/build/repo/src/pages/static-what-we-do.js" /* webpackChunkName: "component---src-pages-static-what-we-do-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

